$padding: 12px;

.root {
  @apply relative;
}

.background {
  @apply fixed inset-0;

  @screen md {
    @apply hidden;
  }
}

.container {
  @apply absolute top-0 right-0;

  padding: 25px;
  z-index: 21;
}

.popup {
  @apply bg-white overflow-hidden;

  border: 1px solid var(--accents-4);
  border-radius: 8px;

  @screen md {
    @apply max-w-[600px];
  }
}

.header {
  @apply bg-cta flex justify-between;

  padding: $padding;
}

.body {
  padding: $padding;
}

.actions {
  @apply flex flex-col items-center space-y-4;

  padding: $padding;

  @screen md {
    @apply flex-row items-stretch space-y-0 justify-end space-x-4;
  }
}
