.link {
  @apply font-bold text-base text-eggplant h-full flex items-center;

  &.sale {
    @apply font-bold;

    color: var(--red-200);
  }

  svg {
    transition: transform 0.15s linear;
  }

  &.active {
    @apply text-purple;

    svg {
      transform: rotateX(180deg);
    }

    .menu {
      @apply visible opacity-100;
    }
  }

  &.linkPadding {
    @apply px-4 first:pl-0 last:pr-0;
  }
}

.menu {
  @apply opacity-0 invisible bg-white absolute left-0 right-0 px-8 pb-6 bottom-px border-b border-b-gray-20 translate-y-full w-full;

  transition: all 0.35s ease-in-out;

  .containerNotFull {
    @apply w-fit mx-auto;
  }
}

.overlayTrigger {
  @apply h-full cursor-default px-4;
}

.mainNavigation {
  @apply grid;

  grid-template-columns: 20% 60% 20%;

  .mainLinks {
    @apply flex flex-row items-center col-start-2;
  }

  .extraLinks {
    @apply flex flex-row items-center justify-end py-6 col-start-3;
  }

  &.searchFocused {
    .mainLinks {
      @apply hidden;
    }

    .extraLinks {
      @apply col-span-3 col-start-2 grid;

      grid-template-columns: 3fr 1fr;

      .searchBar {
        @apply col-start-1 col-span-1 mx-auto max-w-[400px];
      }

      .cartButton {
        @apply col-start-2 col-span-1 justify-self-end;
      }
    }
  }
}

.tileLogo {
  img {
    max-width: 83px;
  }
}

.life360Logo {
  img {
    max-width: 150px;
  }
}
