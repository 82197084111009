@use '@assets/mixins/typography';

%card-text {
  @include typography.text('xsmall');

  @screen md {
    @include typography.text('medium');
  }

  line-height: 1 !important; // to somewhat match figma vertical-trim: cap-height
}

.container {
  @apply relative transition-transform ease-linear cursor-pointer;

  * {
    @apply transition-transform ease-linear cursor-pointer;
  }

  &:hover {
    .image {
      transform: scale(1.08);
    }
  }

  &:hover {
    .addToCart {
      display: initial;
    }
  }
}

.hasAtcBtn {
  .image {
    transform: scale(0.8);
  }
}

.addToCart {
  @apply cursor-pointer;

  width: fit-content;
  height: 30px;
  border: 1px solid var(--accents-3);

  .separator {
    @apply w-px bg-accents-3 mx-xs;

    height: calc(100% + 16px);
  }

  .faAtc {
    padding-right: 2px;
    font-size: 15px;
    color: #777;
  }
}

.imageContainer {
  @apply text-center relative flex items-center justify-center bg-light-gray;

  aspect-ratio: 1;
  border-radius: 6px;
}

p.name {
  @apply focus:no-underline focus:outline-none after:content-[''] after:inset-0 flex justify-center md:justify-start;
  @extend %card-text;

  font-weight: 700;
  margin-top: 7px;

  @screen md {
    font-weight: 400;
  }
}

.rating {
  @apply flex flex-row gap-x-1 items-center justify-center md:justify-start;

  height: 14px;

  &Star {
    font-size: 14px;
    line-height: 1;
  }

  &Text {
    @extend %card-text;
  }
}

.contentWrapper {
  @apply flex flex-col pb-4 text-center md:text-left;

  padding-top: 9px;
  row-gap: 9px;
}

.price {
  @extend %card-text;

  font-weight: 700;
}

.originalPrice {
  @extend %card-text;

  text-decoration-line: line-through;
}

.discount {
  @extend %card-text;

  text-decoration-line: none;
}

.pagination {
  @apply absolute flex justify-center space-x-[5px] bottom-2 z-10 w-full;

  .bullet {
    @apply rounded-full bg-black opacity-30;

    width: 6px;
    height: 6px;
  }

  .bulletActive {
    @apply opacity-100;
  }
}

.imageWrapper {
  @apply hidden md:block;

  .flickImage {
    @apply invisible absolute inset-0 h-full w-full;
  }

  .mainImage {
    @apply h-full w-full;
  }

  &:hover {
    .mainImage {
      @apply invisible;
    }

    .flickImage {
      @apply visible;
    }
  }
}

.badge {
  @include typography.text('base');
  @apply block leading-none p-1;
}
