@use '@assets/mixins/typography';

$block-elements: p, h1, h2, h3, h4, h5, h6, button;

@mixin cell {
  @apply text-left py-s px-xl;
}

%cell {
  @include cell;
  @apply py-xl;

  width: 21.25%;
}

.root {
  @each $el in $block-elements {
    #{$el} {
      @apply mb-5;
    }
  }

  p {
    @include typography.text('text-2');
  }

  @for $i from 1 to 6 {
    h#{$i} {
      @include typography.text('heading-#{$i}');
    }
  }

  ul,
  ol {
    list-style: revert;
    margin-block: 1em;
    padding-inline-start: 40px;
  }

  ol ol {
    list-style-type: upper-alpha;
  }

  ol ol ol {
    list-style-type: lower-roman;
  }

  ol ol ol ol {
    list-style-type: lower-alpha;
  }

  a {
    text-decoration: underline;
    color: var(--hover-cta);
  }

  table {
    @apply border-collapse mx-auto table-fixed overflow-hidden;

    max-width: 1200px;

    tr {
      @apply border-b border-outline-primary;

      z-index: 1;

      &:first-child p {
        @include cell;
        @apply text-center pt-m pb-0;
      }

      td {
        @extend %cell;
        @apply text-center;

        &:first-child p {
          @extend %cell;
          @apply px-s uppercase;

          width: 15%;
        }
      }
    }
  }
}
